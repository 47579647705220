import * as React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
// import logo from "../images/m_logofixed.png"
import "../components/assets/css/style.css"
import "../components/assets/css/hover-effects.css"

import logo1 from "../components/assets/images/logo_layer_back.png"
import logo2 from "../components/assets/images/logo_layer_second.png"
import logo3 from "../components/assets/images/logo_layer_top.png"


export default function Home() {
  return (
    <Layout className="maintenance">
      <Helmet>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js" />
        <script src={withPrefix('script.js')} type="text/javascript" />
      </Helmet>
      <div className="overlay">

        {/* <div className="logo-image"> <a href="https://mchonjb.com/" rel="noreferrer" target="_blank"><img src={logo} alt="Logo" /></a> </div> */}
        <div className="logo_container" style={{width: "323px"}} >
            <div className="logo_n">
                <div className="logo_layer_back" style={{width: "100%", height: "100%"}}>
                    <img src={logo1} alt="" />
                </div>
                <div className="logo_layer_second">
                    <img src={logo2} alt="" />
                </div>
                <div className="logo_layer_top" style={{width: "100%", height: "100%"}} >
	             <a href="/" rel="noreferrer" target="_blank">
                    <img src={logo3} alt="" />
	             </a>
                </div>
            </div>
        </div>
        <div className="text-all">
          <h1 className="center-text"> Under Maintenance. </h1>
          <p style={{ marginBottom: "0px", paddingBottom: "2px" }} >Meanwhile, you can take a look at the rest of my work <a
            href="http://www.behance.net/mchonjb" style={{ textDecoration: "none", color: "#2172df" }} rel="noreferrer"
            target="_blank"> here, at behance.net/mchonjb</a> <br /> or
            <a href="/resume" style={{ textDecoration: "none", color: "#73a5e8" }} rel="noreferrer" target="_blank"> check out my Résumé.</a>
          </p>
        </div>
        
      </div>
    </Layout>

  );
}
